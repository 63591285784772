.ant-layout {
  background-color: $content-color;
}

.ant-menu-inline {
  background-color: $bg-color !important;
}

.ant-pagination-item-link {
  background-color: $tab-bg-active-color !important;
}

.ant-pagination .ant-pagination-item-active {
  background: $color-link-default;
  border-color: $tab-active-color;
}

.ant-pagination .ant-pagination-item-active a {
  color: $white-color;

  &:hover {
    color: $white-color;
  }

  &:hover:before {
    color: $white-color;
  }

  &:hover:after {
    color: $white-color;
  }
}

.ant-layout-header {
  background: $white-color !important;
  border-bottom: 1px solid $color-border-header;
  padding: 0 13px;
}

.ant-layout-header {
  height: 58px !important;
}

.ant-form-item-label {
  padding: 0 !important;
}

.ant-form-item {
  margin-bottom: 3px;
}

.ant-form-item-required {
  font-size: 12px !important;
  color: $color-label-default !important;
}

.ant-form-item-label>label {
  font-size: 12px !important;
  color: $color-label-default !important;
}

.ant-card-head {
  font-size: 14px !important;
  min-height: 33px !important;
}

.ant-drawer-body {
  padding: 0 !important;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: $color-scroll-track;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-scroll-thumb;
    border-radius: 10px;
  }
}

.ant-modal-content {
  padding: 12px 0 !important;
}

.ant-modal-title {
  padding-left: 24px;
}

.modal-content {
  padding-left: 16px;
  padding-right: 16px;
}