@import '../../assets/scss/variables';

.view-row {
  min-height: 45px;
}

.modal {
  display: flex;
  flex-direction: column;
  padding-top: 10px;

  &__title {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: $color-label-default;
  }

  .file-box {
    display: flex;
    align-items: flex-start;
    overflow: auto;
    width: 100%;
    padding: 6px;
    border: 1px solid $color-label-page-default;
    border-radius: 6px;
  }

  &__content {
    padding: 0 30px 10px 30px;
  }

  &__footer {
    display: flex;
    justify-content: center;
    margin: 0 auto 10px auto;
    padding: 0 40px;
  }

  &__buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
  }
}

.avatar-block {
  position: relative;
  min-height: 90px;
  max-height: 90px;
  &__edit {
    position: absolute;
    top: 35px;
    left: 50%;
    translate: -50%;
    border: none !important;
    outline: none !important;
    background: transparent !important;
    box-shadow: none !important;
    color: transparent;
    opacity: 0;
  }
  &__buttons {
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-around;
    z-index: 5;
  }
  &__close {
    // position: absolute;
    // top: -5px;
    // right: -5px;
    // z-index: 5;
  }
  &:hover {
    span {
      opacity: .7;
    }
    button {
      opacity: 1;
      color: #0E1117;
    }
  }
}

.input-block {
  display: inline-flex;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 36px;
  padding: 4px 11px;
  transition: all 0.2s;
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  font-size: 14px;
  line-height: 1.5714285714285714;
  color: #0E1117;
}

.status {
  margin: 0;
  padding: 7px 12px;
  border: none;
  border-radius: 10px;
  &_inactive {
    background: $bg-error-color;
    color: $error-color;
  }
  &_active {
    background: $bg-success-color;
    color: $success-color;
  }
}

.profile {
  display: flex;
  align-items: center;
  cursor: pointer;
  :global {
    .ant-form-item-explain-error {
      height: 0;
    }
  }
  &__avatar {
    border: 1px solid $button-color--border-icon;
  }
  &__title {
    max-width: 150px;
    margin: 0 !important;
    font-size: 17px;
    font-weight: 500;
    color: $color-icon-default;
  }

  &__description {
    margin: 0 !important;
    font-size: 10px;
    font-weight: 400px;
  }

  &__card {
    background: $white-color;
    padding: 10px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  min-width: 550px;
  min-height: 100%;
  padding: 22px 31px;
  &__tab {
    margin-bottom: 24px;
  }

  .label {
    width: 100%;
    font-size: 12px;
    color: $color-label-default;
    white-space: nowrap;

    &__disabled {
      label::before {
        content: '' !important;
      }
    }
  }

  .nowrap {
    label {
      white-space: nowrap;
    }
  }


  .subtitle {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: $color-text-default;
  }

  .title {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    color: $color-text-default;
  }

  .box-route {
    // translate: 25px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: calc(100% + 25px);
    &__item {
      position: relative;
      width: 100%;
      max-width: 100%;
      min-height: 30px;
      padding-left: 15px;
    }

    &__delete {
      translate: 0 -50%;
      position: absolute;
      z-index: 2;
      top: 30%;
      left: -2px;
      max-width: 16px;
      max-height: 16px;
    }
  }

  .label {
    min-height: 20px;
    margin-top: 2px;
    font-size: 12px;
    line-height: 1.5;
    color: $color-label-default;
    vertical-align: middle;
  }
}