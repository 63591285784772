@import '../../assets/scss/variables';

.modal {
  &_hidden {
    z-index: -1;
    opacity: 0;
  }
}

.responsible {
  display: flex;
  align-items: center;
  padding: 8px 0;
  padding-right: 20px;
  &__avatar {
    width: 32px !important;
    min-width: 32px !important;
    max-width: 32px !important;
    height: 32px !important;
    min-height: 32px !important;
    max-height: 32px !important;
    margin-right: 10px;
    border-color: $color-label-default;
  }

  &__title {
    margin: 0 !important;
    font-size: 14px;
    font-weight: 500;
    color: $color-link-default;
  }

  &__description {
    margin: 0 !important;
    font-size: 10px;
    font-weight: 400px;
  }

}
