.carousel {
  justify-content: center;
  top: 9%;
  position: relative;
}

.image-wrapper {
  transition: transform 0.5s ease; /* Плавный переход */
}

.active {
  transform: translateX(0);
  min-width: 700px;
  max-height: 604px;
}
.box-arrow{
  align-self: center;
  text-align: -webkit-center;
}
