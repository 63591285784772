@font-face {
  font-family: 'Manrope';
  src: local('Manrope'),
      url('./font/Manrope-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: local('Manrope'),
      url('./font/Manrope-SemiBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
