@import '../../assets/scss/variables';

.container {
  &__tab {
    :global {
      .ant-tabs-nav {
        padding: 10px 28px !important;
        margin: 0;
      }
      .ant-tabs-tab {
        max-width: none !important;
      }
    }
  }
}