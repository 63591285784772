.react-tel-input .form-control{
  width: 100% !important;
  height: 32px !important;
  border-radius: 6px !important;
  border: 1px solid #d9d9d9;
}
.react-tel-input .flag-dropdown{
  border-radius: 6px 0 0 6px;
  border: 1px solid #d9d9d9;
}
