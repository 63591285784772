@import '../../../../assets/scss/variables';

.status {
  margin: 0;
  padding: 7px 12px;
  border: none;
  border-radius: 10px;
  &_inactive {
    background: $bg-error-color;
    color: $error-color;
  }
  &_active {
    background: $bg-success-color;
    color: $success-color;
  }
}