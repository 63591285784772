@import '../../assets/scss/variables';

.input-block {
  display: inline-flex;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 36px;
  padding: 4px 11px;
  transition: all 0.2s;
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  font-size: 14px;
  line-height: 1.5714285714285714;
  color: #0E1117;
}

.file-box {
  padding: 10px 6px;
  border: 1px solid #44537126;
  border-radius: 6px;
  min-height: 130px;
  max-height: 230px;
  overflow: auto;
}

.list {
  overflow: auto;
  width: 100%;
  max-height: 150px;
  padding-right: 10px;

  &__label {
    display: inline-block;
    width: 100%;

    &-text {
      padding: 0;
      font-size: 12px;
      line-height: 1.1;
      color: $color-label-default;
      white-space: nowrap;
    }
  }

  &__button {
    outline: none !important;
    min-width: 16px;
    max-width: 16px;
    min-height: 16px;
    max-height: 16px;
    margin-top: 30px;
    background: none !important;
    border: none !important;

    svg {
      min-width: 16px;
      max-width: 16px;
      min-height: 16px;
      max-height: 16px;
    }
  }

  &__upload {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    padding: 5px 10px;
    border: 1px solid $button-color;
    border-radius: 5px;
    font-size: 12px;
    line-height: 1;

    &_disabled {
      border-color: $button-color--border;
    }

    &-button {
      color: $button-color;

      &:hover {
        opacity: .8;
        cursor: pointer;
      }
    }
  }
}

.button {
  border-color: $button-color;
  &_error {
    color: $error-color !important;
    background-color: $bg-error-color !important;
    border-color: $bg-error-color !important;
  }
  &_success {
    color: $success-color !important;
    background-color: $bg-success-color !important;
    border-color:  $bg-success-color !important;
  }
}

.button-link {
  color: $button-color;
  border-color: $button-color;
}

.icon {
  background: none !important;
  color: $color-icon-default !important;

  &:hover {
    color: $color-text-default !important;
  }
}

.modal {
  display: flex;
  flex-direction: column;
  padding-top: 10px;

  &__pdf {
    height: 80vh;
  }

  &__title {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: $color-label-default;
  }

  .file-box {
    display: flex;
    align-items: flex-start;
    overflow: auto;
    width: 100%;
    min-height: 300px;
    max-height: 500px;
    padding: 6px;
    padding-bottom: 25px;
    border: 1px solid $color-label-page-default;
    border-radius: 6px;
  }

  &__content {
    padding: 0 30px 10px 30px;
  }

  &__footer {
    display: flex;
    justify-content: center;
    margin: 0 auto 10px auto;
    padding: 0 40px;
  }

  &__buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
  }
}

.label-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  padding: 4px 15px;
  background: $white-color;
  border: 1px solid $button-color--border;
  border-radius: 6px;
  color: $button-color--default;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  min-width: 550px;
  min-height: 100%;
  padding: 22px 31px;

  &__tab {
    margin-bottom: 0;
    [role='tablist']{
      margin-bottom: 0 !important;
    }
  }

  .col-vertical {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
  }

  .label {
    width: 100%;
    font-size: 12px;
    color: $color-label-default;
    white-space: nowrap;

    &__disabled {
      label::before {
        content: '' !important;
      }
    }
  }

  .nowrap {
    label {
      white-space: nowrap;
    }
  }

  .label-subtitle {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: $color-text-default;
  }
  .subtitle {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    color: $color-text-default;
  }

  .title {
    margin: 0;
    font-size: 20px;
    font-weight: 800;
    color: $color-text-default;
  }
}