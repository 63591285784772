@import '../../../../assets/scss/variables';;

.box {
  position: relative;
  .input {
    width: 100% !important;
    padding-right: 10px;
    &__icon {
      position: absolute;
      top: 50%;
      right: 12px;
      width: 12px;
      translate: 0 -50%;
      cursor: pointer;
      color: $color-input-icon;
      &_hidden {
        visibility: hidden;
        opacity: 0;
      }
    }
  }
}