@import "../../../assets/scss/_variables";

.Tabs {
  width: 100%;
  border: none;
  border-bottom: none;
  border-radius: 4px;
  box-shadow: 0 1px 10px rgba($shadow-color, 0.15);
  background-color: $white-color;

}
.TabDefault {
    :global {
      .ant-tabs-nav {
        margin: 0;
        margin-bottom: 30px;
        background: $bg-color;
        cursor: pointer;
        font-size: 16px;
        &-list {
          justify-items: flex-start !important;
          flex-wrap: wrap !important;
          gap: 20px 15px !important;
        }

        .ant-tabs-tab.ant-tabs-tab {
          height: auto !important;
          border-bottom: 2px solid transparent !important;
          margin: 1px 0 0 0 !important;
          border-radius: 0 !important;
          background: $tab-color !important;
          border: none;
          padding: 5px 2px !important;
          max-width: 200px;
          white-space: normal;
          text-align: center;


          color: $tab-text-color;
          font-size: 14px !important;
          line-height: 1.14 !important;

          + .ant-tabs-tab.ant-tabs-tab {
            margin-left: 1px;
          }

          &.ant-tabs-tab-active.ant-tabs-tab-active {
            background: $white-color;
            border-bottom: 2px solid $tab-active-color !important;
            z-index: 100;

            .ant-tabs-tab-btn {
              color: $tab-active-color;
              text-shadow: none;
            }
          }

          @for $i from 1 through 100 {
            &:nth-child(#{$i}) {
              z-index: 100 - $i;
            }
          }

          .ant-tabs-tab-btn {
            user-select: none;

            &:focus,
            &:active {
              color: inherit;
            }
          }

          .ant-tabs-tab-remove {
            margin: 0 -5px 0 5px;
            padding: 0;
            display: flex;

            .anticon {
              svg {
                fill: $dark-color;
              }
            }
          }
        }
      }

      .ant-tabs-extra-content {
        padding: 0;
      }

      .ant-tabs-content {
        padding: 0;
      }
    }
}


.errorTabs {
  :global {
    .ant-tabs-nav {
      .ant-tabs-tab:has([data-error=true]) {
        // background-color: $error-color !important;
        color: $error-color !important;
        .ant-tabs-tab-btn, div[data-error=true] {
          color: $error-color !important;
        }
        &.ant-tabs-tab-active.ant-tabs-tab-active {
          .ant-tabs-tab-btn, div[data-error=true] {
            color: $error-color !important;
          }
        }
      }
    }
  }
}