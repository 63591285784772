.box-map{
  display: flex;
  min-height: 100px;
  padding: 6px 12px 6px 12px;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid rgba(68, 83, 113, 0.15);
  background: #FFF;
  &.text{
    border: 0;
    overflow: hidden;
    color:  #858FA3;
    text-align: center;
    font-variant-numeric: lining-nums tabular-nums stacked-fractions;
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 84px;
    flex: 1 0 0;
    align-self: stretch;
    place-content: center;
  }
}
