@import '../../assets/scss/variables';

.crm {
  :global {

    .ant-tabs-top .ant-tabs-nav::before {
      border-bottom: 0 !important;
    }

    .ant-tabs-tab {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      flex-shrink: 0 !important;
      height: 24px !important;
      margin: 0 0 0 12px !important;
      padding: 7px 18px !important;
      background: $white-color !important;
      border-radius: 10px !important;

      &:hover {
        background: $tab-bg-active-color !important;
        color: $tab-bg-active-color !important;
      }
    }

    .ant-tabs-ink-bar {
      background: $white-color !important;

      &:hover {
        background: $tab-bg-active-color !important;
      }
    }

    .ant-tabs-tab-btn {
      color: $color-text-default !important;
    }

    .ant-tabs-tab-active {
      background: $tab-bg-active-color !important;
      color: $tab-active-color !important;

      &:hover {
        background: $tab-bg-active-color !important;
        color: $tab-active-color !important;
      }
    }
  }

}