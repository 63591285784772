@import '~antd/dist/reset.css';
@import './globalAnt.scss';

body {
  font-family: $font-page;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  color: $color-text-default;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content {
  background-color: $content-color;
  padding-right: 43px;
}

.sider {
  background-color: $bg-color !important;
  border-right: 1px solid $color-border-header;

  &.avatar {
    border-bottom: 1px solid $color-border-header;
  }
}

.container {
  display: inline-flex;
  padding: 14px 24px 14px 16px;
  align-items: center;
  gap: 555px;
  width: 100%;
  border-bottom: 1px solid $color-border-header;
}

.label-card-14 {
  overflow: hidden;
  color: #445371;
  font-variant-numeric: lining-nums tabular-nums stacked-fractions;
  font-feature-settings: 'clig'off, 'liga'off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.label-card-16 {
  overflow: hidden;
  font-variant-numeric: lining-nums tabular-nums stacked-fractions;
  font-feature-settings: 'clig'off, 'liga'off;
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 100% */
}

.label-card-20 {
  font-variant-numeric: lining-nums tabular-nums stacked-fractions;
  font-feature-settings: 'clig'off, 'liga'off;
  text-overflow: ellipsis;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 80% */
}

.label-card-24 {
  font-variant-numeric: lining-nums tabular-nums stacked-fractions;
  font-feature-settings: 'clig'off, 'liga'off;
  text-overflow: ellipsis;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 80% */
}

.label-card-24-500 {
  overflow: hidden;
  font-variant-numeric: lining-nums tabular-nums stacked-fractions;
  font-feature-settings: 'clig'off, 'liga'off;
  text-overflow: ellipsis;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.text-card-14 {
  overflow: hidden;
  font-variant-numeric: lining-nums tabular-nums stacked-fractions;
  font-feature-settings: 'clig'off, 'liga'off;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.text-card-12 {
  font-variant-numeric: lining-nums tabular-nums stacked-fractions;
  font-feature-settings: 'clig'off, 'liga'off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.text-card-10 {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  /* 120% */
}

// общий класс скрола можно переделать в миксин
.all-custom-v-scroll {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: $color-scroll-track;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-scroll-thumb;
    border-radius: 10px;
  }
}