.h100{
  height: 95vh;
}
.login-form{
  width: 500px;
}
.radio{
  margin-bottom: 10px;
  width: 100%;
}
.radio-button{
  width: 50%;
}

.login-form-forgot {
  float: right;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}

.ahref {
  color: #05F;
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums stacked-fractions;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.card-login{
  display: inline-flex;
  padding: 26px 57px 26px 57px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 50px;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.15);
}

.container-login {
  .ant-card-head-wrapper{
    text-transform: lowercase;
  }
}
