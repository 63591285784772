@import '../../assets/scss/_variables';

.page-container {



  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }

  .dsg-add-row {
    display: none !important;
  }

  .dsg-input {
    font-size: 14px !important;
  }

  .ant-select-selection-item {
    font-size: 14px !important;
  }

  .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card,
  :where(.css-dev-only-do-not-override-11xg00t).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-card,
  :where(.css-dev-only-do-not-override-11xg00t).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-circle,
  :where(.css-dev-only-do-not-override-11xg00t).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle {
    flex-wrap: nowrap !important;
    display: inline-flex;
    flex-direction: row-reverse;
  }

  .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item,
  :where(.css-dev-only-do-not-override-11xg00t).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item,
  :where(.css-dev-only-do-not-override-11xg00t).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item,
  :where(.css-dev-only-do-not-override-11xg00t).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload-list.ant-upload-list-picture-circle .ant-upload-list-item {
    width: inherit;
  }

  .ant-modal .ant-modal-footer {
    margin-right: 20px;
  }

  .cvv-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    border-radius: 12px;
    background: #F8F8F8;
    padding: 5px 16px;
    height: 59px;
  }

  .cvv-box {
    height: 57px;
  }

  .cvv-text {
    font-size: 14px;
  }

  .cvv-text--1 {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cvv-text--2 {
    font-size: 12px;
    color: #E14453 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text-import {
    color: #05F;
    text-align: center;
    font-variant-numeric: lining-nums tabular-nums stacked-fractions;
    font-feature-settings: 'clig'off, 'liga'off;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
  }

  .form-comments {
    padding: 8px;
    max-height: 200px;
    overflow: auto;
    min-height: 486px;
  }

  .box-comment {
    margin: 3px 16px;
    border: 1px solid #D8D8D8;
    border-radius: 8px;
    padding: 8px 16px;
  }

  .add-comment-box {
    margin: 16px 16px;
  }

  .rowChat {
    width: 93%;
    position: relative;
    left: 42px;
  }

  .ant-upload {
    width: 102px !important;
    height: 102px !important;
  }

  .ant-upload-list-item-container {
    width: 102px !important;
    height: 102px !important;
  }

  .text-base {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    &.sm {
      font-variant-numeric: lining-nums tabular-nums stacked-fractions;
      font-feature-settings: 'clig'off, 'liga'off;
      text-overflow: ellipsis;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;

      &.blue {
        color: #05F;
        cursor: pointer;
        margin: 0;
      }

      &.red {
        color: #E14453;
        cursor: pointer;
      }
    }

    &.xs {
      overflow: hidden;
      font-variant-numeric: lining-nums tabular-nums stacked-fractions;
      font-feature-settings: 'clig'off, 'liga'off;
      text-overflow: ellipsis;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }

  }

  .container-photo {
    border-radius: 12px;
    border: 1px solid #E3E5EA;
    background: #FFF;
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
    max-width: 100%;
    height: 145px;
    gap: 8px;
  }

  .modal-container {
    padding: 0 16PX;
  }

  .container-modal {
    padding: 16px;
  }

  .box-container {
    min-height: 100px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 6px;
    border: 1px solid #E3E5EA;
    background: #FFF;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.00) inset;
    padding: 12px;
  }

  .box-image-container {
    gap: 10px;
    display: flex;
    border: 1px solid #E3E5EA;
    border-radius: 8px;
    padding: 8px;
    width: 102px !important;
    overflow: hidden;
    cursor: pointer;

    &.box-image {
      width: 100%;
      height: 78px;
      transition: background 0.5s ease;

      &:hover {
        background: #000;
        background-size: cover;
      }
    }
  }

  .box-icon-import {
    display: inline-flex;
    padding: 29.25px;
    justify-content: center;
    align-items: center;
    border-radius: 14.625px;
    border: 0.002px solid rgba(255, 255, 255, 0.00);
    background: #FCE8EA;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.00) inset;
  }

  .box-import {
    margin: 5px 16px;
  }

  .box-text-import {
    width: 67%;
    white-space: normal;
  }

  .column-name-crm {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    width: 100%;
    padding: 4px 11px;
    height: 36px;
    overflow: hidden;

    &.disabled {
      pointer-events: none;
      opacity: 0.4;
      background: #e3e2e2;
      border: 1px solid #959494;
    }
  }

  .btnMessage {
    position: relative;
    left: -29px;
    width: 27px !important;
    height: 27px;
    top: 3px;
  }

  .rowChatInfo {
    color: #05F;
  }

  .box-popover {
    margin: 0;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 250px;
  }

  .box-container-active {
    padding: 0 12px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  .infinite-scroll-component {
    gap: 7px;
    display: grid;
  }

  .scrollableDiv {
    overflow-y: auto;
    gap: 6px;
    display: grid;
    padding-right: 11px;
    scrollbar-width: thin;
  }

  .table-title {
    font-weight: bold;
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 2;
    height: 40px;
    padding-top: 12px;
  }

  .table-container {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .table-header,
  .table-row {
    display: flex;
    background: #fff;
    border-radius: 5px;
  }

  .table-header {
    font-weight: bold;
    position: sticky;
    top: 40px;
    z-index: 1;
    background: #fff;
  }

  .table-cell,
  .table-header>div {
    flex: 1;
    padding: 10px;
  }

  .table-row {
    background: #F8F8F8;
    border-radius: 12px;
  }

  .custom-tooltip .ant-tooltip-inner {
    color: $color-text-default;
  }

  .active-search-btn {
    background-color: #737373;
    color: #fff;
    border-color: #737373;
  }

  .disabled-div {
    color: #a0a0a0;
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.6;
  }

  .enabled-div {
    color: $color-text-default;
    cursor: pointer;
    pointer-events: auto;
    opacity: 1;
  }

}