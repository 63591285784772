.upload {
  min-height: min-content;
  :global {
    .ant-upload {
      width: auto !important;
      height: auto !important;
    }
  }
}
.link {
  height: auto;
  padding: 0;
  margin: 0;
  background: none;
  box-shadow: none;
  &:hover {
    background: transparent !important;
    box-shadow: none !important;
  }
}