@import '../../../assets/scss/variables';

.input {
  border: none !important;
  cursor: text !important;
  padding-left: 25px !important;
  font-size: 13px !important;
  line-height: 1.5714285714285714 !important;
  color: $color-text-default !important;
  font-weight: 500 !important;
}

.container {
  padding: 0 !important;
  font-size: 12px !important;
}

.button {
  border-color: transparent !important;
  background: transparent !important;
  padding-left: 0 !important;
  div {
    padding: 0 !important;
    border: none !important;
  }
}
.dropdown {
}