@import "../../assets/scss/_variables";

.header-screen-container{
  display: flex;
  width: 100%;
  height: 71px;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-top: 1px solid #FFF;
  background: #1F1F1F;
  color: $button-color--text;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
