.element {
	display: block;
	margin: 3px;
	padding: 3px;
	border: 1px solid #44537126;
	border-radius: 5px;
}
.button {
	border-radius: 5px;
	background-color: #0055FF;
	color: #fff;
	font-size: 14;
}
.p {
	color: #0055FF;
}

::placeholder { /* Most modern browsers support this now. */
  color: #000000;
	// padding-left: 7px;
}
.email {
	position: absolute;
	left: 150
}

.formEmail {
	position: relative;
}

.smallStep {
	margin: 5px 0;
}

.bigStep {
	margin: 20px 0;
}
.container {
	width: 400px;
}

.input {
	margin-bottom: 10px;
	padding-right: 35px;
	&__password {
		:global {
			.ant-input-suffix {
				translate: 4px 0;
			}
		}
	}
}