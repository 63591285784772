.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90vw;
  height: 90vh;
  margin: auto;

  &__title {
    font-size: 32px;
  }

  &__link {
    font-size: 20px;
  }
}