.loginButton {
	width: '100%';
}

.color {
	color: #0055FF;
	cursor: pointer;
	margin: 0;
}

.container {
	width: 400px;
}

.input {
	&__password {
		:global {
			.ant-input-suffix {
				translate: 4px 0;
			}
		}
	}
}
