@import '../../assets/scss/variables';

.stock {
  padding-top: 5px;
  max-height: 250px;
  overflow: auto;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: $color-scroll-track;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-scroll-thumb;
      border-radius: 10px;
    }
}