@import '../../../assets/scss/variables';

.menu {
  ul[role="menu"]:first-of-type {
    padding-right: 20px !important;
  }

  &__action {
    padding: 4px 0 0 15px !important;
  }

  &__submenu {
    ul[role="menu"] {
      min-width: 150px !important;
    }

    li[role="menuitem"]:has([data-id="link"]):hover {
      background-color: $hover-item-menu !important;

      span {
        color: $color-link-default !important;
      }
    }
  }
}

.container {
  &__over {
    margin: 0 16px;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #b8b9b9 #dddddd;
  }
}
.clear__filters{
  color: $button-color;
  font-size: 12px;
  cursor: pointer;
}