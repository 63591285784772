@import '../../../../assets/scss/variables';


// HACK: стили подогнать
.container {
  .label {
    width: 100%;
    color: $color-label-default;
    font-size: 12px;
    white-space: nowrap;
    &__disabled {
      label::before {
        content: '' !important;
      }
    }
  }
  table > tbody > tr:last-of-type {
    td {
      border-bottom: none !important;
    }
  }
  table {
    th, td {
     padding: 5px 10px !important;
   }
  }
  :global {
    .ant-table-wrapper .ant-table-thead > tr > th {
      background: transparent !important;
    }
    .ant-table-body, .ant-table-content {
      scrollbar-width: thin !important;
      &::-webkit-scrollbar {
        width: 6px !important;
        height: 6px !important;
      }

      &::-webkit-scrollbar-track {
        background: $color-scroll-track !important;
      }

      &::-webkit-scrollbar-thumb {
        background: $color-scroll-thumb !important;
        border-radius: 10px !important;
      }
    }
  }

  .action {
    &-delete {
      translate: 0 8px;
      color: $error-color;
      outline: none;
      border: none;
    }
  }
}