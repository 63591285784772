@import '../../../../assets/scss/variables';

.button {
  width: 200px;
  height: auto;
  min-height: 32px;
  line-height: 1;
  white-space: wrap;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 600px;
  min-height: 600px;
  overflow: auto;
  border: 1px solid #44537126;
  border-radius: 6px;
  padding: 5px;
  margin-left: -1px;
  margin-right: -1px;
  margin-bottom: 10px;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: $color-scroll-track;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-scroll-thumb;
    border-radius: 10px;
  }
}

.row {
  display: grid !important;
  min-height: 60px;
  align-items: stretch;
}

.icon {
  background: none !important;
  color: $color-icon-default !important;
  &:hover {
    color: $color-text-default !important;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 550px;
  min-height: 100%;
  padding: 22px 31px;
  &__tab {
    margin-bottom: 24px;
  }

  .label {
    width: 100%;
    color: #a3a9b1;
    line-height: 1.4 !important;
    &__disabled {
      label::before {
        content: '' !important;
      }
    }
  }

  .subtitle {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: $color-text-default;
  }

  .title {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    color: $color-text-default;
  }

  .box-route {
    // translate: 25px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: calc(100% + 25px);
    &__item {
      position: relative;
      width: 100%;
      max-width: 100%;
      min-height: 30px;
      padding-left: 15px;
    }

    &__delete {
      translate: 0 -50%;
      position: absolute;
      z-index: 2;
      top: 30%;
      left: -2px;
      max-width: 16px;
      max-height: 16px;
    }
  }


  .subtitle {
    margin-bottom: 10px;
  }

  .box-list {
    .comments-box {
      border: none;
    }

    &_border {
      .subtitle {
        margin-left: 6px;
      }

      border: 1px solid #44537126;
      border-radius: 6px;
      padding-bottom: 0;
      margin-left: -1px;
      margin-right: -1px;
      margin-bottom: 10px;

      .textarea {
        width: calc(100% - 12px);
        margin: 0 6px;
      }
    }
  }

  .label {
    min-height: 20px;
    margin-top: 2px;
    font-size: 12px;
    line-height: 1.5;
    color: $color-label-default;
    vertical-align: middle;
  }

  .view-row {
    &__title {
      margin: 0 !important;
      font-size: 14px;
      color: $color-label-default;
    }

    &__subtitle {
      margin: 0 !important;
      font-size: 12px;
      &_link {
        cursor: pointer;
        color: $color-link-default;
      }
    }
  }

  .responsible {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 8px 0;
    padding-right: 20px;
    &__avatar {
      width: 32px !important;
      height: 32px !important;
      font-size: 15px;
    }

    &__title {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: $color-link-default;
    }

    &__description {
      margin: 0;
      font-size: 10px;
      font-weight: 400px;
    }

  }

  .comments-box {
    max-width: 700px;
    min-height: 100px;
    margin-bottom: 14px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .comments-box,
  .file-box {
    padding: 6px;
    border: 1px solid #44537126;
    border-radius: 6px;
  }

  .file-box {
    width: 100%;
    min-height: 200px;
    max-height: 210px;
    overflow: auto;
    padding-bottom: 25px;
  }

  .comment {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0;
    padding: 8px 16px;
    padding-left: 0;
    border-radius: 8px;
    &__title {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
    }

    &__date {
      margin: 0;
      font-size: 10px;
      font-weight: 400;
      line-height: 1;
      color: $color-link-default;
    }

    &__avatar {
      width: 16px !important;
      height: 16px !important;
      margin: 0;
      font-size: 7px;
    }

    &__text {
      margin: 0;
      font-size: 12px;
      font-weight: 400;
      line-height: 1;
    }

    &__text-box {
      max-width: calc(100% - 100px);
      margin-left: 32px;
    }
  }
}