.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  padding: 5px 0;
  &__rate {
    color: #0055FF;
  }
  &__description {
    border-radius: 6px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 600;
    color: #858FA3;
  }
}