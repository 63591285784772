@import '../../assets/scss/variables';

.menu {
  background: $white-color;
  :global {
    .ant-menu-item, .ant-menu-submenu-title {
      padding-left: 5px !important;
      &:hover {
        color: $color-link-default !important;
        svg {
          color: currentColor;
        }
      }
    }
    .ant-menu-item-only-child{
      padding-left: 45px !important;
    }

    .ant-menu-item-icon:hover {
      background: none;
      border-radius: 6px;
      padding: 5px;
      color: $color-link-default;
    }

    .ant-menu-item-icon {
      border-radius: 6px;
      padding: 5px;
    }

    .ant-menu-item-icon:focus {
      background: $color-bg-hover-icon-menu !important;
      border-radius: 6px;
      padding: 5px;
    }
  }

  &__link {
    &:hover {
      color: $color-link-default !important;
      opacity: 1;
    }
    &_child {
      padding-left: 5px;
    }
  }
  &__icon {
    font-size: 24px !important;
    svg {
      color: $button-color--default;
    }
  }
}