@import '../../assets/scss/variables';

.chat {
  &__view {
    translate: 0 5px;
    position: relative;
    padding-top: 5px;
  }
  &__control {
    padding: 20px;
    padding-bottom: 5px;
    background-color: $content-color;
    border: 1px solid $button-color--border;
    border-radius: 10px;
  }
  &__view {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    gap: 20px;
    overflow: auto;
    min-height: 150px;
    max-height: 200px;
  }
}

.rent_request_title{
  display: none;
}
.rent_request_open_modal_chat{
  display: none;
}
.rent_request_control{
  background-color: transparent;
  border: none;
}
.rent_request_chat{
  min-height: 70vh;
}
.rent_request_select{
  :global(.ant-select){
    width: 350px !important;
  }
}

.box-comment {
  padding: 8px 16px;
  border: 1px solid $button-color--border;
  border-radius: 8px;
  &__avatar {
    width: 32px !important;
    min-width: 32px !important;
    max-width: 32px !important;
    height: 32px !important;
    min-height: 32px !important;
    max-height: 32px !important;
    border-color: $color-label-default;
  }
  &__row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    min-width: 100%;
    position: relative;
    &__message{
      max-width: 85%;
    }
  }

  &__title {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin: 0 !important;
  }
  &__description {
    margin: 0 !important;
    font-size: 12px;
    font-weight: 500;
  }

  &__delete {
    border: none !important;
    color: $error-color;
    translate: 0 -2px;
  }
  &__data {
    // position: absolute;
    color: $button-color;
    line-height: .9;
  }
}

.input-box {
  display: inline-flex;
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 32px;
  padding: 2px;
  transition: all 0.2s;
  background: $white-color;
  border-width: 1px;
  border-style: solid;
  border-color: $button-color--border;
  border-radius: 6px;
  font-size: 14px;
  line-height: 1.5714285714285714;
  color: $color-text-default;
  &__input {
    padding: 0 10px;
    border: none !important;
  }
}

.button {
  translate: 8px 0;
  width: 30px !important;
  height: 30px !important;
  background-color: $button-color;
  color: $button-color--text;
  &:hover {
    opacity: .8;
    background-color: $button-color !important;
    color: $button-color--text !important;
  }
  svg {
    width: 20px;
  }
}

.label-subtitle {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: $color-text-default;
}

.modal {
  &__content {
    margin-top: 20px;
    padding: 0 20px;
    border-top: 1px solid $color-label-default;
  }
  &__view {
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 20px;
    min-height: 500px;
    max-height: 600px;
    padding: 20px;
  }
}