@import "../../../../assets/scss/variables";

.formModalRentRequest {
  // margin-top: 16px;
  margin: 16px;
  :global(.ant-tabs-tab) {
    min-width: fit-content !important;
    :global(.ant-tabs-tab-btn) {
      color: $tab-text-color !important;
      font-weight: 600;
      white-space: nowrap;
    }
  }

  :global(.ant-input-number .ant-input-number-input) {
    min-height: 36px !important;
    width: 98% !important;
    padding: 7px 11px !important;
  }
  :global(.FormInputNumber) {
    max-width: 98% !important;
  }

  :global(.ant-tabs-nav::before) {
    border-bottom: none;
  }
  :global(.ant-tabs-nav) {
    margin-bottom: 38px;
  }
  // :global(
  //     :where(.css-dev-only-do-not-override-abkopn).ant-form-item
  //       .ant-form-item-label
  //       > label
  //   ) {
  //   color: $color-label-default;
  //   font-size: 12px;
  //   font-weight: 400;
  // }
  :global(.ant-form-item .ant-form-item-label > label) {
    color: $color-label-default;
    font-size: 12px;
    font-weight: 400;
  }
  :global(.ant-form-item-label) {
    padding: 0px !important;
  }

  :global(.ant-divider-with-text::after) {
    display: none !important;
  }

  :global(.ant-divider-with-text::before) {
    display: none !important;
  }

  :global(.ant-table-cell) {
    padding: 6px 0px !important;
  }

  :global(.ant-table-thead) {
    font-size: 14px;
  }

  :global(.ant-table-row) {
    font-size: 12px;
    background-color: $bg-light-color;
    border-radius: 30px !important;
  }

  :global(.ant-table-tbody > tr > td:first-child) {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
  }

  :global(.ant-table-tbody > tr > td:last-child) {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }

  :global(.ant-table-tbody > tr > td) {
    border-bottom: none !important;
  }

  :global(.ant-table-wrapper) {
    border-spacing: 0 8px !important;
  }

  :global(.ant-table-tbody) {
    border-spacing: 0 8px !important;
  }

  :global(.ant-table) {
    border-collapse: separate;
    border-spacing: 0 12px !important;
    border: none;
  }

  :global(.table) {
    border-collapse: separate;
    border-spacing: 0 !important;
    border: none;
  }

  // :global(
  //     :where(.css-dev-only-do-not-override-abkopn).ant-table-wrapper table
  //   ) {
  //   border-collapse: separate;
  //   border-spacing: 0 12px !important;
  //   border: none;
  // }
  :global(.ant-table-wrapper table) {
    border-collapse: separate;
    border-spacing: 0 12px !important;
    border: none;
  }
  // :global(:where(.css-dev-only-do-not-override-abkopn).ant-table-wrapper) {
  //   border-collapse: separate;
  //   border-spacing: 0 0 !important;
  //   border: none;
  // }
  :global(.ant-table-wrapper) {
    border-collapse: separate;
    border-spacing: 0 0 !important;
    border: none;
  }

  // :global(
  //     :where(.css-dev-only-do-not-override-abkopn).ant-table-wrapper::before
  //   ) {
  //   display: none;
  // }
  :global(.ant-table-wrapper::before) {
    display: none;
  }
}

.inputWithCheckbox {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 12px;
}

.inputName {
  max-width: 36%;
}

.requestTab {
  // :global(.style_list__4sEZ9) {
  //   max-height: fit-content !important;
  //   overflow-y: visible !important;
  //   gap: 0 !important;
  // }
  :global(.all-custom-v-scroll) {
    max-height: fit-content !important;
    overflow-y: visible !important;
  }
  .checkboxesWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    position: absolute;
    right: 15%;
    bottom: 32px;
    :global(.ant-checkbox + span) {
      font-size: 12px;
      color: $color-label-default;
      padding-inline-start: 2px;
    }
  }

  .locationReturn {
    position: relative;
    padding-left: 7px;
    // width: 100%;
    // :global(
    //     :where(
    //         .css-dev-only-do-not-override-abkopn
    //       ).ant-flex-justify-space-between
    //   ) {
    //   border: 1px solid #d9d9d9;
    //   border-radius: 10px;
    //   margin-bottom: 10px;
    //   padding: 5px;
    // }
    :global(.ant-flex-justify-space-between) {
      border: 1px solid #d9d9d9;
      border-radius: 10px;
      margin-bottom: 10px;
      padding: 5px;
    }
    :global(.ant-form-item) {
      width: 100% !important;
    }
  }

  .locationRelease {
    position: relative;
    padding-left: 7px;
    :global(.ant-form-item) {
      width: 100% !important;
    }
  }

  .locationReturnApprooval {
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 5px;
  }

  .locationReturnInput {
    position: relative;
    max-width: 285px !important;
    :global(.ant-form-item) {
      width: 285px !important;
    }
  }

  .betInput {
    max-width: 50% !important;
  }

  :global(.ant-form-item) {
    margin-bottom: 10px;
  }

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  // }
  :global(.ant-input) {
    padding: 6px 11px;
    width: 98%;
  }

  :global(.ant-select-selector) {
    height: 36px !important;
    width: 98%;
  }

  :global(.ant-select) {
    height: 36px !important;
    width: 98% !important;
  }
}

.RequestTablesDivider {
  margin: 0 !important;
  font-weight: 600;
  min-height: 10px !important;
  align-items: flex-end;
}

// .RequestTables {
// min-width: fit-content;
// overflow: visible;
// border: 1px solid #bebebea2;
// border-radius: 15px;
// padding: 0px 10px;
// :global(.ant-table-cell) {
//   max-width: 100px !important;
//   position: static !important;
//   :global(.ant-input) {
//     max-width: 100px;
//   }
// }
// :global(.ant-table-thead) {
//   :global(:where(.css-dev-only-do-not-override-abkopn).ant-checkbox-wrapper) {
//     position: absolute;
//     top: 7%;
//     right: -6%;
//     display: none;
//   }
// }
// :global(.ant-table-row) {
//   position: relative;
// }
// :global(
//     :where(.css-dev-only-do-not-override-abkopn).ant-checkbox
//       .ant-checkbox-inner:after
//   ) {
//   top: 25%;
// }
// :global(:where(.css-dev-only-do-not-override-abkopn).ant-checkbox-wrapper) {
//   position: absolute;
//   top: 7%;
//   right: -6%;
// }
// :global(.ant-table-selection) {
//   position: static;
// }
// }
.Return {
  align-self: flex-end;
  width: 65%;
  position: relative;
  margin-top: 75px;
  :global(.returnContainer) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    :global(.reset) {
      background-color: transparent;
      border: none;
      box-shadow: none;
    }
    p {
      margin-bottom: 0px;
      font-size: 16px;
      font-weight: 500;
    }
  }
  :global(.ant-input) {
    padding: 10px 11px;
  }
}

:global(.ant-col-14) {
  display: flex;
  flex-direction: column;
}

.formModalRequest {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
  min-height: 100%;
  .buttonContainer {
    width: 61%;
    align-self: flex-end;
    margin-bottom: 35px;
    margin-top: 20px !important;
  }
}

.modalContent {
  text-align: center;
}

.modalTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
}

.checkboxGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 16px;
}

.actionButtons {
  display: flex;
  justify-content: space-between;
}

.CancelReasonModal {
  position: relative;
  :global(.ant-modal-content) {
    padding: 20px 25px !important;
  }
  :global(.ant-modal-title) {
    margin-bottom: 35px;
  }
  .checkboxGroup {
    gap: 14px !important;
    margin-bottom: 30px;
  }
  :global(.ant-modal-footer) {
    display: flex;
    justify-content: space-around;
  }
  :global(.ant-btn-primary) {
    padding: 19px 76px;
    border: none;
    background-color: $button-color;
    color: $button-color--text;
  }
}

.sendButton {
  background: transparent;
  border-color: $button-color;
  color: $button-color;
  position: absolute;
  right: 17px;
  top: -66px;
  font-size: 12px;
  padding: 0px 32px;
  height: 25px;
}

.ApprovalTableContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 123px;
  margin-bottom: 90px;
  width: 100%;
}

.ApprovalTable {
  width: 100%;
  :global(.style_table-body__gCG4m) {
    max-height: 80px !important;
  }
}

.approvalInputs {
  :global(.ant-input-outlined) {
    color: $color-text-default;
  }
  :global(.ant-input-outlined[disabled]) {
    background-color: transparent;
  }
}

.approvalInputsDisabled {
  :global(.ant-input-outlined) {
    color: #8a8a8a;
  }
  :global(.ant-input-outlined[disabled]) {
    background-color: #f6f6f6;
  }
}

.additional {
  width: 63%;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-right: 20px;
  margin-bottom: 40px;
  .agreed,
  .not-agreed {
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 20px;
    font-weight: 600;
    .icon-container,
    .error-icon-container {
      align-items: center;
      border: 0px solid;
      border-color: transparent;
      border-radius: 14.62px;
      box-shadow: inset 0px 0px 0px transparent;
      display: inline-flex;
      flex: 0 0 auto;
      justify-content: center;
      padding: 29.25px;
      position: relative;
    }
    .icon-container {
      background-color: $bg-success-color;
    }
    .error-icon-container {
      background-color: $bg-error-color;
    }
  }
  .agreed {
    color: $success-color;
  }
  .not-agreed {
    color: $error-color;
  }
  .Return {
    margin-top: 10px;
    width: 100%;
  }
}

.right-column {
  padding: 11px 16px;
  background-color: #fcfcfc;
  border: 1px solid #e2e4e9;
  border-radius: 6px;
  min-width: 62% !important;

  .date_request {
    position: relative;
    :global(.ant-picker) {
      min-height: 100px !important;
    }
  }

  .date-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3px;
    width: 39%;
    // margin-bottom: 22px;
    position: relative;
    &__item {
      max-width: 90%;
      width: 90%;
      .form__label {
        :global(.ant-form-item-label) {
          position: absolute !important;
          top: -22px !important;
          label {
            // position: absolute;
            // top: -20px;
            width: 100%;
            &::after {
              display: none;
            }
          }
        }
      }
    }

    &__separator {
      box-sizing: border-box;
      width: 6px;
      min-width: 6px;
      max-width: 6px;
      height: 1px;
      min-height: 1px;
      max-height: 1px;
      margin-top: 35px;
      margin-bottom: auto;
      border: 1px solid $color-label-default;
      margin: 0;
    }
    :global(.ant-picker) {
      padding: 6px 11px !important;
    }
  }

  .label {
    color: #a3a9b1;
    line-height: 1.4 !important;
    &::after {
      margin: 0 !important;
      display: none !important;
      content: "" !important;
    }
    &__disabled {
      label::before {
        margin: 0 !important;
        display: none !important;
        content: "" !important;
      }
    }
    &__hidden {
      label {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  .request-info {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    :global(.ant-form-item) {
      width: 39%;
    }
    :global(.ant-picker) {
      padding: 6px 11px !important;
    }
  }
}

.visible {
  display: flex;
}

.collapsed {
  width: 1000px;
}

.divider {
  width: 2px;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  transition: background-color 0.5s;
}

.divider:hover {
  background-color: $color-icon-default;
}

.icon {
  position: absolute;
  top: 50%;
  left: -13px;
  transform: translateY(-50%);
  display: none;
  font-size: 12px;
  padding: 4px;
  cursor: pointer;
}

.iconLeft {
  transform: translateY(-50%) rotate(0deg);
}

.iconRight {
  transform: translateY(-50%) rotate(180deg);
}

.visible {
  display: flex;
}

.collapsed {
  display: none;
}

.pdf {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 100px;
  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 14px;
    text-align: center;
    margin-bottom: 20px;
    width: 39%;
    &__btn {
      width: 100%;
      color: $button-color;
      height: 35px;
    }
  }
  &__options {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 20px;
    align-self: center;
    width: 50%;
    &__check {
      font-size: 16px;
    }
  }
}

.file-box {
  padding: 15px;
  border: 1px solid #44537126;
  border-radius: 6px;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 135px;
  padding-bottom: 25px;
  margin-bottom: 100px !important;
}

.deals {
  padding: 15px;
  border: 1px solid #44537126;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 135px;
  padding: 10px 15px;
}

.release__info {
  width: 40%;
}

.hide__btn {
  color: #858fa3;
  align-self: center;
  width: 100%;
  border: 1px solid #44537126;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
  margin-bottom: 25px;
  &:focus,
  &:hover {
    color: #858fa3 !important;
  }
}
