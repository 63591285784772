@import '../../assets/scss/variables';

:global {
  .drawer-header{
    padding: 22px 31px 0 31px;
  }

  .ant-card-head-wrapper{
    font-size: 14px !important;
  }
  .content--drawer{
    padding: 16px 31px;
  }
  .ant-drawer .ant-drawer-mask {
    background: transparent;
  }

  .ant-drawer-content-wrapper {
    // top: 10px !important;
  }
  .drawer-header {
    padding: 22px 31px 0 31px;
  }

  .drawer-title {
    font-size: 20px;
    font-weight: 500;

    &.terminal {
      font-weight: 400;
      color: $color-link-default;
    }
  }

  .ant-drawer-body {
    padding: 0 !important;
    overflow: unset;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }
  }
}
.drawer {
  width: auto !important;
  border-radius: 10px;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }


  &__container {
    min-width: calc(45vw - 250px);
  }


  &__resize-button {
    translate: 0 -50%;
    position: absolute;
    z-index: 2;
    top: 50%;
    min-width: 14px;
    max-width: 14px;
    min-height: 50px;
    cursor: e-resize;
    &[data-placement="right"] {
      left: -15px;
    }
    &[data-placement="left"] {
      right: -15px;
    }
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    width: 36px;
    height: 36px;
    background: #FFF;
    cursor: pointer;
    border-radius: 6px;
    box-shadow: 1px 1px 5px $color-label-default;
    &:hover {
      border: 1px solid $color-label-default;
    }
    &[data-placement="right"] {
      left: -44px;
    }
    &[data-placement="left"] {
      right: -44px;
    }
  }

}

.board {
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: -40px;
  width: 100vw;
  background: transparent;
  cursor: e-resize;
  z-index: 1000;

  &__line {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 50%;
    min-width: 2px;
    max-width: 2px;
    padding: 2px;
    border-left: 1px solid $color-icon-default;
    z-index: 999;
    opacity: 0;
  }
}