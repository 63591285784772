@import '../../../assets/scss/variables';

.icon {
  background: none !important;
  color: $color-icon-default !important;
  &:hover {
    color: $color-text-default !important;
  }
}

.label-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white-color;
  color: $button-color--default;
  border: 1px solid $button-color--border;
  border-radius: 6px;
  padding: 4px 15px;
  width: 130px;
}

.tabs-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 550px;
  min-height: 100%;
  padding: 22px 31px;
  gap: 20px;

  &__tab {
    margin-bottom: auto;
  }

  .label {
    width: 100%;
    color: $color-label-default;
    font-size: 12px;
    white-space: nowrap;

    &__disabled {
      label::before {
        content: '' !important;
      }
    }
  }

  .subtitle {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: $color-text-default;
  }

  .title {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    color: $color-text-default;
  }
}