$button-color: #0055FF;
$button-color--hover: #0041C3;
$button-color--hover-before: #003CB4;
$button-color--text: #ffffff;
$button-color--radius: 6px;
$button-color--default: #445371;
$button-color--border-icon: #E2E4E9;
$button-color--border-icon-hover: #109BFF;
$button-color--border: #d9d9d9;

$content-color: #F8F8F8;
$border-color: #E2E4E9;
$shadow-color: #E2E4E9;
$white-color: #fff;
$bg-error-message: #0e11170a;
$bg-color: #fff;
$tab-color: #fff;
$tab-text-color: #0E1117;
$tab-active-color: #0148D6;
$tab-bg-active-color: #E1E9FA;
$tab-text-active-color: #0148D6;
$tab-bg-active-color: #E1E9FA;
$tab-text-active-color: #0E1117;
$dark-color: #0E1117;
$success-color: #00B288;
$success-light-color: #E6F6EC;
$error-color: #E14453;
$error-light-color: #FCE8EA;
$white-color: #fff;
$black-color: #000;
// background
$bg-light-color: #F8F8F8;
$bg-success-color: #E6F6EC;
$bg-error-color: #FCE8EA;

$color-hover-icon-menu: #0148D7;
$color-bg-hover-icon-menu: #E1E9FA;
$hover-item-menu: #0055ff1a;
$color-text-default: #0E1117;
$color-label-page-default: #445371; // по макету
$color-input-icon: #0e111740;
$color-label-default: #a3a9b1;
$color-icon-default: #858FA3;
$color-icon-disabled: #0e111740;;
$color-link-default: #05F;
$color-checkbox-default: #37F;
$color-primary: #05F;

$color-scroll-track: #f1f1f1;
$color-scroll-thumb: #888;
$color-border-header: #D8D8D8;

$font-page: -apple-system, Manrope, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;
