@import '../../../../assets/scss/variables';

.list {
  max-height: 350px;
  overflow: auto;
  padding-right: 10px;
  width: 100%;

  &__label {
    display: inline-block;
    width: 100%;
    &-text {
      color: $color-label-default;
      font-size: 12px;
      line-height: 1.1;
      white-space: nowrap;
      padding: 0;
    }
  }

  &__button {
    border: none !important;
    outline: none !important;
    background: none !important;
    margin-top: 30px;
    max-height: 16px;
    min-height: 16px;
    max-width: 16px;
    min-width: 16px;
    svg {
      max-height: 16px;
      min-height: 16px;
      max-width: 16px;
      min-width: 16px;
    }
  }

  &__upload {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    line-height: 1;
    padding: 5px 10px;
    height: 36px;
    border-radius: 5px;
    border: 1px solid $button-color;

    &_disabled {
      border-color: $button-color--border;
    }
    &-button {
      color: $button-color;

      &:hover {
        cursor: pointer;
        opacity: .8;
      }
    }
  }
}