@import '../../../../assets/scss/variables';

.drop {
  li[role="menuitem"]:hover {
    background-color: transparent !important;
  }

  li[role="menuitem"]:has([data-id="link"]):not([data-disabled="true"]):hover {
    background-color: $hover-item-menu !important;
  }

  li[role="menuitem"]:has([data-disabled="true"]) {
    pointer-events: none;
    cursor: default;
  }
}

.overlap-group {
  position: relative;
  overflow-x: auto;
  width: 100%;
  max-width: 100%;
  min-height: 250px;
  max-height: 450px;
  border: 1px solid #e3e5ea;
  border-radius: 6px;
  padding: 15px;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  .table {
    display: table;
    table-layout: auto;
    border-collapse: collapse !important;
    width: 100%;
    height: 100%;

    &__container {
      justify-items: baseline;
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
      min-height: 100px;
      margin-left: 0;
      padding: 0 7px 7px 12px;
    }

    &__header {
      background: white;
      border-radius: 6px;

      &-row {
        background: white;
      }

      &-cell {
        padding: 5px 15px;
        min-width: 120px;
      }

      .label-card-16 {
        text-align: left;
      }
    }

    &__header-fixed {
      position: sticky;
      top: -15px;
      left: 0;
      background: white;
      border-radius: 6px;
      z-index: 10;

      &-row {
        background: white;
        padding: 10px 0;
      }

      &-cell {
        padding: 10px 15px;
        min-width: 120px;

        &:last-of-type {
          min-width: auto;
        }
      }

      .label-card-16 {
        text-align: left;
      }
    }

    &__cell,
    &__index {
      padding: 5px 15px;
      max-width: 250px;
    }

    &__index {
      min-width: auto;
    }

    &__body {
      // display: grid;
      // gap: 6px;
      background: #F8F8F8;
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 200px;
      min-width: 100%;
      // margin-right: 12px;
      // padding-right: 0;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
      }
    }

    /* Стили для строк таблицы */
    &__row {
      // display: flex;
      // align-items: center;
      // position: relative;
      // margin-right: 12px;
      border-radius: 12px;
      border: 5px solid #fff;
      margin-bottom: 10px;
      // padding: 5px 0;

      * {
        margin-bottom: 0;
      }
    }

    // &__row:last-child {
    //   background: #F8F8F8;
    //   border-radius: 12px;
    // }

    &__menu {
      margin-left: 0;
    }

    &__button {
      width: 20px !important;
      min-width: 20px !important;
      height: 20px !important;
      min-height: 20px !important;
    }

  }

  .menu {
    &-icon {
      display: flex;
      align-items: center;
      gap: 5px;
      min-width: 40px;
      padding: 0 5px;

      &__button {

        &-error,
        &-success,
        &-depo {
          align-items: center;
          align-self: center;
          width: 24px !important;
          min-width: 24px !important;
          height: 24px !important;
          min-height: 24px !important;
          background-color: $white-color !important;
          font-size: 12px !important;
        }

        &-error {
          color: $error-color;
        }

        &-success {
          color: $success-color;
        }

        &-depo {
          color: $color-link-default;
        }
        &-error, &-success, &-depo {
          &:disabled {
            color: $color-icon-disabled;
          }
        }
      }
    }
  }
}