.list-ul{
  list-style-type: none;
}
.list-li{
  cursor: pointer;
  padding: 8px;
  &:hover {
   background-color: #F2F6FF;
    padding: 8px;
    border-radius: 6px;
  }
}
