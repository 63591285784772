@import '../../../assets/scss/variables';

.checkbox {
  &:hover {
    opacity: .9;
  }
  :global {
    .ant-checkbox-checked.ant-checkbox-disabled {
      box-sizing: border-box;
      background-color: $color-label-default !important;
      border-color: $color-label-default !important;
      .ant-checkbox-inner {
        border-color: $color-label-default !important;
        background-color: $color-label-default !important;
        &::after {
          border-color: $white-color !important;
        }
      }
    }
    .ant-checkbox-checked {
      box-sizing: border-box;
      background-color: $color-checkbox-default !important;
      border-color: $color-checkbox-default !important;
      .ant-checkbox-inner {
        background-color: $color-checkbox-default !important;
        border-color: $color-checkbox-default !important;
        width: 20px !important;
        height: 20px !important;
        font-size: 14px;
        &::after {
          width: .7em !important;
          height: 1em !important;
          scale: .8;
          inset-inline-start: 20% !important;
          top: 40%;
        }
      }
    }
    .ant-checkbox-inner {
      width: 20px !important;
      height: 20px !important;
      border: 2px solid $color-label-default !important;
    }
  }
}

.element {
	appearance: none;
	width: 20px;
	height: 20px;
	border: 2px solid #44537140;
	border-radius: 3px;
	cursor: pointer;
	&:checked {
		background-color: #0055FF;
		border: 1.5px solid #0055FF;
		background-image: url('./check.svg');
	}
	&.image-choice {
		background-color: #1F1F1F !important;
		border: 1.5px solid #FFFFFF;
	}
}
.label{
	display: flex;
	&.image-label {
		gap: 8px;
	}
}