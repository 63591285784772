@import '../../assets/scss/variables';

.container {
  position: absolute;
  z-index: 10;
  top: 58px;
  bottom: 0;
  right: 0;
  overflow: hidden;
  min-width: 48px;
  max-height: calc(100vh - 100px);
  padding: 8px;
  padding-right: 5px;
  transition: all 0.3s;
  background: $white-color;
  border-left: 1px solid $color-border-header;

  &__profile {
    margin-bottom: 5px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__skeleton {
    display: flex !important;
    min-width: 50px !important;
    min-height: 50px !important;

    :global {
      .ant-skeleton-header {
        height: 50px !important;
        padding: 0 !important;
      }
    }
  }

  div &__scroll {
    scrollbar-width: none !important;
  }

}