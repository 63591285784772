.ref-responsible {
  float: right;
  font-size: 12px;
  color: #a3a9b1;
  cursor: pointer;
}

.ant-space-item {
  width: 100%;
}

.ant-modal .ant-modal-content {
  height: auto;
}