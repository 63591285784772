@import '../../assets/scss/variables';

.history {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 550px;
  min-height: 100%;
  padding: 22px 31px;
  &__table {
    max-width: 100%;
    div {
      max-height: 300px;
      min-height: 300px;
    }
  }
  &__column {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &__row {
    display: grid;
    gap: 10px;
    grid-template-columns: 250px repeat(2, 1fr);
    max-width: 100%;
  }
  &__background {
    min-height: 32px;
    padding: 5px 10px;
    background: $bg-light-color;
    border-radius: 10px;
  }
  .label {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding-left: 7px;
    &_selected {
      padding-left: 0;
    }
  }

  .separator {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    &__circle {
      display: block;
      width: 4px;
      height: 4px;
      min-width: 4px;
      min-height: 4px;
      max-width: 4px;
      max-height: 4px;
      border-radius: 50%;
      background: currentColor;
    }
  }
  .checkboxes {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 5px;
    &__label {
      display: inline-flex;
      font-size: 16px;
      color: $color-text-default;
    }
  }
}