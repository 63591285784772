.upload {
  :global {
    .ant-upload-list {
      flex-direction: row-reverse !important;
    }
  }
  &-view {
    button {
      display: none !important;
      visibility: hidden !important;
      opacity: 0 !important;
      pointer-events: none !important;
    }
  }
}