@import "../../assets/scss/_variables";

.language {
  display: flex;
  gap: 10px;

  &__item {
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: bold;
  }
}
.date {
  display: flex;
  align-items: center;
  flex: 0 0 40%;
  max-width: 40%;
  font-size: 12px;
  font-weight: bold;
}
.menu {
  li[role="menuitem"]:hover {
    background-color: $hover-item-menu;
    color: $color-link-default;
  }
  li[role="menuitem"] {
    text-transform: uppercase;
    font-weight: bold !important;
  }
}

.circle {
  display: inline-block;
  width: .5em;
  height: .5em;
  min-width: .5em;
  min-height: .5em;
  max-width: .5em;
  max-height: .5em;
  border-radius: 50%;
  background: currentColor;
}