@import "../../../assets/scss/_variables";

.container {
  :global {
    .ant-upload-list.ant-upload-list-picture-card {
      gap: 25px 15px !important;
      .ant-upload-list-item-container {
        width: 76px !important;
        height: 76px !important;
      }

      .ant-upload.ant-upload-select {
        order: -1;
        width: 76px !important;
        height: 76px !important;
      }
    }

    .ant-upload-list.ant-upload-list-text {
      .ant-upload-list-item-container {
        width: auto !important;
        height: auto !important;
        [data-type='file-title'] {
          max-width: 150px !important;
        }
      }

      .ant-upload.ant-upload-select {
        order: -1;
      }
    }
  }

  .loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    &__button {
      cursor: pointer;
    }

  }
}

.links {
  display: flex;
  gap: 5px;
  &__delete {
    border: none;
    color: $error-color;
  }
}

.box-description {
  position: absolute;
  text-align: center;
  z-index: 10;
  top: 100%;
  left: 0;
  right: 0;
}

.file {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;

  &-description {
    margin: 0 !important;
    margin-top: 5px !important;
    font-size: 10px !important;
    font-weight: 400;
    line-height: 1.2;
    text-align: center;
  }

  &__icon {
    font-size: 24px;
    &:hover {
      color: $button-color--hover;
    }
  }

  &__menu {
    position: absolute;
    top: 3px;
    right: 3px;
    width: 15px;
    height: 15px;
  }

  &__button {
    box-shadow: none;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 100%;
    border: none;
    text-align: left;
    svg[data-icon="anticon"] {
      width: 18px;
      min-width: 18px;
      height: 18px;
      min-height: 18px;
    }

    &:hover {
      border: 1px solid currentColor;
    }

    &_delete {
      background: $error-light-color;
      color: $error-color;
    }
  }
}