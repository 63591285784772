@import '../../../assets/scss/variables';

.button {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  outline: none;
  box-shadow: none;
  display: inline-flex;
  align-items: center;
  height: auto !important;
  width: auto !important;
  padding: 0 !important;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  background: transparent;
  color: $color-icon-default;
  font-size: inherit !important;
}


.icon {
  border: none !important;
  outline: none !important;
  background: none !important;
  font-size: inherit !important;
  color: $color-icon-default;
  &:hover {
    color: $color-text-default !important;
  }
}