@import '../../assets/scss/variables';

.swap {
  translate: 0 20px;
  color: $color-primary;
  background: none !important;
  border: none !important;
}

.pdf {
  position: relative;
  min-height: 90vh;
  width: 100%;
  gap: 20px;
  &__logo {
    &-title {
      font-size: 9px !important;
      font-weight: 600 !important;
      line-height: .88 !important;
      white-space: nowrap;
    }
    &-text {
      font-size: 8px !important;
      font-weight: 400 !important;
      line-height: 1.4 !important;
      margin: 0 !important;
      color: $black-color;
    }
  }
  &__page {
    display: flex;
    flex-direction: column;
    gap: 50px;
    height: 100%;
    padding: 33px 24px;
  }
  &__print {
    position: absolute;
    top: -15px;
    left: 0;
    color: $color-icon-default;
    background: transparent !important;
    border: none !important;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  &__grid {
    display: grid;
    grid-template-columns: repeat(3, calc(90% / 3));
    grid-template-rows: repeat(8);
    justify-content: space-around;
    gap: 20px;
    margin-bottom: 30px;
  }
  &__title {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: $black-color;
    margin: 0 !important;
  }
  &__small {
    font-size: 5px !important;
    font-weight: 400 !important;
    color: $black-color;
    margin: 0 !important;
  }
  &__subtitle {
    font-size: 12px !important;
    font-weight: 500 !important;
    color: $black-color;
    margin: 0 !important;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &-text {
      margin: 0 !important;
    }
  }
  &__line {
    min-width: 100px;
    font-size: 12px !important;
    font-weight: 500 !important;
    text-align: center !important;
    color: $black-color;
    border-bottom: 1px solid $color-text-default;
  }
}

.modal {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  &__pdf {
    height: 80vh;
  }
  &__title {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: $color-label-default;
  }

  .file-box {
    overflow: auto;
    width: 100%;
    min-height: 300px;
    max-height: 500px;
    padding: 6px;
    padding-bottom: 25px;
    border: 1px solid $color-label-page-default;
    border-radius: 6px;
  }

  &__content {
    padding: 0 30px 10px 30px;
  }

  &__footer {
    display: flex;
    justify-content: center;
    padding: 0 40px;
    margin: 0 auto 10px auto;
  }
  &__buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
  }
}

.icon {
  background: none !important;
  color: $color-icon-default !important;
  &:hover {
    color: $color-text-default !important;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  // &__table {}
}

.transporter {
  &__add {
    max-width: min-content;
    padding-left: 0;
    color: $color-label-default;
  }

  &__delete {
    translate: 0 10px;
    color: $error-color;
    background: transparent;
    border: none;
  }
}

.tab {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.labelLink {
  cursor: pointer;
  color: $color-link-default;
}

.checkboxes {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
  &__label {
    display: inline-flex;
    font-size: 16px;
    color: $color-text-default;
  }
}

.text-card-14 {
  margin: 0;
}

.date-block {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 5px;
  &__item {
    min-width: calc(50% - 16px);
  }

  &__separator {
    box-sizing: border-box;
    width: 6px;
    min-width: 6px;
    max-width: 6px;
    height: 1px;
    min-height: 1px;
    max-height: 1px;
    margin-top: 35px;
    margin-bottom: auto;
    border: 1px solid $color-label-default;
  }
}

.pl-10 {
  padding-left: 10px;
}

.relative {
  position: relative;
  padding-top: 5px;
  &__block {
    display: flex;
    align-items: center;
    gap: 20px;
    position: absolute;
    top: 5px;
    left: 350px;
  }
  &__status {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 90px;
    min-height: 30px;
    border-radius: 5px;
    font-family: 12px;
    font-weight: 500;
    line-height: 1.1;
    &_success {
      background: $bg-success-color;
      color: $success-color;
    }
    &_new {
      background: $bg-error-color;
      color: $error-color;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 550px;
  min-height: 100%;
  padding: 22px 31px;
  &__tab {
    margin-bottom: 24px;
  }

  &__label {
    label {
      white-space: nowrap;
    }
  }

  .label {
    // width: 100%;
    color: #a3a9b1;
    line-height: 1.4 !important;
    &__disabled {
      label::before {
        content: '' !important;
      }
    }
    &__hidden {
      label {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  .subtitle {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: $color-text-default;
  }

  .title {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    color: $color-text-default;
    &_label {
      color: $color-label-default;
    }
  }

  .box-route {
    // translate: 25px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: calc(100% + 25px);
    &__item {
      position: relative;
      width: 100%;
      max-width: 100%;
      min-height: 30px;
      padding-left: 15px;
    }

    &__delete {
      translate: 0 -50%;
      position: absolute;
      z-index: 2;
      top: 30%;
      left: -2px;
      max-width: 16px;
      max-height: 16px;
    }
  }


  .subtitle {
    margin-bottom: 10px;
  }

  .box-list {
    .comments-box {
      border: none;
    }

    &_border {
      .subtitle {
        margin-left: 6px;
      }

      border: 1px solid #44537126;
      border-radius: 6px;
      padding-bottom: 0;
      margin-left: -1px;
      margin-right: -1px;
      margin-bottom: 10px;

      .textarea {
        width: calc(100% - 12px);
        margin: 0 6px;
      }
    }
  }

  .label {
    min-height: 20px;
    margin-top: 2px;
    font-size: 12px;
    line-height: 1.5714285714285714;
    color: $color-label-default;
    vertical-align: middle;
  }

  .view-row {
    &__title {
      margin: 0 !important;
      font-size: 14px;
      font-weight: 500;
      color: $color-label-default;
    }

    &__subtitle {
      margin: 0 !important;
      font-size: 12px;
      font-weight: 400;
      &_link {
        cursor: pointer;
        color: $color-link-default;
      }
    }
  }

  .responsible {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 8px 0;
    padding-right: 20px;
    &__avatar {
      width: 32px !important;
      height: 32px !important;
      font-size: 15px;
    }

    &__title {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: $color-link-default;
    }

    &__description {
      margin: 0;
      font-size: 10px;
      font-weight: 400px;
    }

  }

  .comments-box {
    max-width: 700px;
    min-height: 100px;
    margin-bottom: 14px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .comments-box,
  .file-box {
    padding: 6px;
    border: 1px solid #44537126;
    border-radius: 6px;
  }

  .file-box {
    overflow: auto;
    width: 100%;
    min-height: 100px;
    max-height: 500px;
    padding-bottom: 25px;
  }

  .comment {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0;
    padding: 8px 16px;
    padding-left: 0;
    border-radius: 8px;
    &__title {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
    }

    &__date {
      margin: 0;
      font-size: 10px;
      font-weight: 400;
      line-height: 1;
      color: $color-link-default;
    }

    &__avatar {
      width: 16px !important;
      height: 16px !important;
      margin: 0;
      font-size: 7px;
    }

    &__text {
      margin: 0;
      font-size: 12px;
      font-weight: 400;
      line-height: 1;
    }

    &__text-box {
      max-width: calc(100% - 100px);
      margin-left: 32px;
    }
  }
}

.buttons {
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
  }
}

.button {
  &__primary {
    max-width: fit-content;
    border-color: $color-primary;
    color: $color-primary;
  }
  &__excel {
    width: 32px !important;
    min-width: 32px !important;
    height: 32px !important;
    min-height: 32px !important;
    background: $white-color;
    border: 1px solid $color-label-default !important;
    color: $color-label-page-default;
  }
}

.menu {
  &-icon {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 40px;
    padding: 0 5px;
    &:first-child {
      width: 80px;
    }

    &__button {
      &-error, &-success, &-depo {
        align-items: center;
        align-self: center;
        width: 24px !important;
        min-width: 24px !important;
        height: 24px !important;
        min-height: 24px !important;
        background-color: $white-color !important;
        font-size: 12px !important;
      }
      &-error {
        color: $error-color;
      }
      &-success {
        color: $success-color;
      }
      &-depo {
        color: $color-link-default;
      }
    }
  }
}

.overlap-group {
  position: relative;
  overflow-x: auto;
  width: 100%;
  max-width: 100%;
  min-height: 192px;
  border: 1px solid #e3e5ea;
  border-radius: 6px;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }


  .table {
    display: flex;
    width: 100%;
    &__container {
      justify-items: baseline;
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
      min-height: 100px;
      margin-left: 0;
      padding: 0 7px 7px 12px;
    }

    &__header {
      display: flex;
      align-items: center;
      min-width: 100%;
      margin-right: 12px;
      background: white;
      border-radius: 6px;
      .label-card-16 {
        text-align: left;
      }
    }

    &__cell,
    &__index {
      flex: 1;
      align-self: center;
      min-width: 120px;
      margin-right: 10px;
      padding: 5px;
      text-align: left;
    }

    &__index {
      display: flex;
      justify-content: center;
      min-width: 40px;
      max-width: 40px;
    }

    &__cell:has(input):not(.ant-picker-input) {
      width: 120px;
      padding: 0;
    }

    &__cell:has([type="search"]) {
      flex: 1;
      min-width: 120px;
      padding: 0;
    }

    &__body {
      display: grid;
      gap: 6px;
      overflow-x: hidden;
      overflow-y: auto;
      min-width: 100%;
      max-height: 200px;
      margin-right: 12px;
      padding-right: 0;
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
      }
    }

    /* Стили для строк таблицы */
    &__row {
      display: flex;
      align-items: center;
      position: relative;
      margin-right: 12px;
      background: #F8F8F8;
      border-radius: 12px;
      padding: 5px 0;
      * {
        margin-bottom: 0;
      }
    }

    &__row:last-child {
      background: #F8F8F8;
      border-radius: 12px;
    }

    &__menu {
      margin-left: 0;
    }

    &__button {
      width: 20px !important;
      min-width: 20px !important;
      height: 20px !important;
      min-height: 20px !important;
    }

  }
}

.drop {
  li[role="menuitem"]:hover {
    background-color: transparent !important;
  }

  li[role="menuitem"]:has([data-id="link"]):not([data-disabled="true"]):hover {
    background-color: $hover-item-menu !important;
  }

  li[role="menuitem"]:has([data-disabled="true"]) {
    pointer-events: none;
    cursor: default;
  }
}