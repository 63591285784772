@import '../../../../assets/scss/variables';

.view {
  width: 100% !important;
  &__title {
    margin: 0 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: $color-label-default !important;
  }

  &__subtitle {
    margin: 0 !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    &_link {
      cursor: pointer;
      color: $color-link-default !important;
    }
  }
}