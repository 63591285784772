.input{
  width: 100%;
  &.header-depo {
    width: 114px;
  }
}
.space{
  width: 100%;
  padding: 8px 12px;
}
