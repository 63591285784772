@import "../../../../../assets/scss/variables";

.table-wrapper {
  max-height: 400px;
  border: 1px solid $border-color;
  padding: 0px;
  height: fit-content;
  border-radius: 7px;
  position: relative;
  width: 96%;
  margin-bottom: 7px;
  &.non-editable {
    .ant-select {
      pointer-events: none;
    }
  }

  .ant-select:focus-visible {
    outline: none !important;
    box-shadow: none !important;
  }

  :global(.ant-select-arrow) {
    display: none;
  }
  :global(.ant-table-cell) {
    max-width: 100px !important;
    position: static !important;
    :global(.ant-input) {
      max-width: 100px;
    }
  }
  // :global(.ant-table-thead) {
  //   :global(.ant-checkbox-wrapper) {
  //     position: absolute;
  //     top: 7%;
  //     right: -6%;
  //     display: none;
  //   }
  // }
  :global(.ant-table-row) {
    position: relative;
  }
  // :global(.ant-checkbox .ant-checkbox-inner:after) {
  //   top: 25%;
  // }
  // :global(.ant-checkbox-wrapper) {
  //   position: absolute;
  //   top: 7%;
  //   right: -6%;
  // }
  :global(.ant-table-selection) {
    position: static;
  }

  .table {
    display: grid;
    grid-template-rows: auto;
    padding: 5px 5px 0px 15px;
    position: relative;
    width: 100%;
  }

  .table-header {
    width: 100%;
    display: grid;
    grid-template-columns: var(--columns);
    padding: 0;
    font-weight: bold;
    margin-bottom: 10px;
    padding-right: 12px;
  }

  .table-header-cell {
    padding: 0px;
    text-align: left;
    white-space: wrap;
    line-height: 100%;
    padding: 5px 0 3px 0;
  }

  .table-body {
    display: grid;
    grid-auto-rows: auto;
    max-height: 200px;
    overflow-y: auto;
    padding: 0 !important;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #a1a9b8;
      border-radius: 10px;
    }
  }

  .table-row {
    display: grid;
    grid-template-columns: var(--columns);
    background-color: $content-color;
    margin-bottom: 6px;
    height: 34px;
    border-radius: 13px;
    padding: 0 5px;
    margin-right: 2px;
  }

  .table-cell {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .table-input {
    padding: 0;
    height: fit-content;
    height: 30px;
    border: none;
    background-color: transparent;
    font-size: 12px;
    color: $color-text-default;
  }

  .table-input:hover {
    background-color: transparent;
  }

  .table-input::placeholder {
    font-size: 12px;
    color: $color-label-default;
  }

  :global(.ant-input-outlined:focus) {
    background-color: transparent !important;
    border: none;
    box-shadow: none;
  }
}
.table-body {
  :global(
      .ant-select-focused:where(.css-abkopn).ant-select-outlined:not(
          .ant-select-disabled
        ):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
        .ant-select-selector
    ) {
    box-shadow: 0 0 0 0px rgb(255 255 255 / 0%);
    outline: none;
  }
  // :global(.ant-select-focused.ant-select-outlined) {
  //   box-shadow: 0 0 0 0px rgb(255 255 255 / 0%);
  //   outline: none;
  // }

  :global(.ant-select-selector) {
    height: 30px !important;
    border: none !important;
    background-color: transparent !important;
    font-size: 12px;
    color: $color-label-default;
    padding: 0 11px 0 0 !important;
    box-shadow: 0 0 0 0px rgb(255 255 255 / 0%);
    outline: none;
  }

  :global(.ant-select) {
    height: 30px !important;
  }

  :global(.ant-select-selection-placeholder) {
    font-size: 12px !important;
    color: $color-label-default !important;
  }

  :global(.ant-select-single.ant-select-show-arrow .ant-select-selection-item) {
    font-size: 12px !important;
    color: $color-text-default !important;
  }

  :global(.ant-select-arrow) {
    color: $color-icon-default;
    display: block;
  }
}
.remaining-cost-header {
  line-height: 100%;
}

.table-wrapper {
  align-items: flex-start;
}

.table-checkbox {
  margin-bottom: 10px;
  position: relative;
  z-index: 1;
}

.checkbox-container {
  position: absolute;
  top: 50px !important;
  right: -25px !important;
  left: auto !important;
  height: 75% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  background-color: transparent;
}

.checkbox-item {
  height: 34px;
  display: block;
  margin-bottom: 6px;
}
