@import '../../../../assets/scss/variables';

.table {
  table>tbody>tr:last-of-type {
    td {
      border-bottom: none !important;
    }
  }

  table {

    th,
    td {
      padding: 5px 10px !important;
    }
  }

  :global {
    .ant-table-wrapper .ant-table-thead>tr>th {
      background: transparent !important;
    }

    .ant-table-content {
      min-height: 400px;
    }

    .ant-table-body,
    .ant-table-content {
      scrollbar-width: thin !important;

      &::-webkit-scrollbar {
        width: 6px !important;
        height: 6px !important;
      }

      &::-webkit-scrollbar-track {
        background: $color-scroll-track !important;
      }

      &::-webkit-scrollbar-thumb {
        background: $color-scroll-thumb !important;
        border-radius: 10px !important;
      }
    }
  }
}