// :root {
  // }
$components-closebutton-primary-bg-color-default:rgba(68, 83, 113, 0.1);
$components-closebutton-primary-border-color-default:rgba(255, 255, 255, 0);
$components-closebutton-s-border-radius:6px;
$components-closebutton-s-height:24px;
$components-focus-soft-color:rgba(0, 85, 255, 0.1);
$components-functionbutton-s-label-padding-vertical:2px;
$components-functionbutton-s-spacing:4px;
$components-functionbutton-secondary-label-color-default:rgba(14, 17, 23, 1);
$components-functionbutton-tertiary-label-color-hover:rgb(0, 73, 219);
$components-toastnotification-m-actionbuttons-spacing:16px;
$components-toastnotification-m-border-radius:6px;
$components-toastnotification-m-content-padding-bottom:0px;
$components-toastnotification-m-content-padding-left:12px;
$components-toastnotification-m-content-padding-right:0px;
$components-toastnotification-m-content-padding-top:0px;
$components-toastnotification-m-header-spacing:4px;
$components-toastnotification-m-text-spacing:12px;
$components-toastnotification-primary-warning-bg-color:rgba(255, 255, 255, 1);
$components-toastnotification-primary-warning-border-color:rgba(255, 255, 255, 0);
$components-toastnotification-primary-warning-header-color:rgba(14, 17, 23, 1);
$components-toastnotification-primary-warning-iconcontainer-border-color:rgba(255, 255, 255, 0);
$components-toastnotification-primary-warning-subtitle-color:rgba(68, 83, 113, 1);
$theme-border-radius-base:6px;
$theme-border-radius-m:$theme-border-radius-base;
$colorButton: #4096ff;
$colorButtonOutline: rgba(64, 150, 255, 0.514);

.screen {
  align-items: flex-start;
  background-color: $components-toastnotification-primary-warning-bg-color;
  border: 0px solid;
  border-color: $components-toastnotification-primary-warning-border-color;
  border-radius: $components-toastnotification-m-border-radius;
  display: flex;
  gap: 16px;
  height: 142px;
  padding: 12px 0 0 24px;
  position: relative;
  width: 502px;

  .ant-btn {
    font-weight: 600;
  }

  .ant-btn-default:not(:disabled):not(.ant-btn-disabled) {
    border: 0;
    box-shadow: none;
  }
  .ant-btn:not(:disabled):focus-visible {
    color: $colorButton;
    outline-color: $colorButtonOutline;
  }

  .icon-container {
    align-items: center;
    background-color: #fce8ea;
    border: 0px solid;
    border-color: $components-toastnotification-primary-warning-iconcontainer-border-color;
    border-radius: 14.62px;
    box-shadow: inset 0px 0px 0px transparent;
    display: inline-flex;
    flex: 0 0 auto;
    justify-content: center;
    padding: 29.25px;
    position: relative;

    .element-alert-attention {
      height: 58.5px !important;
      position: relative !important;
      width: 58.5px !important;
    }
  }

  .content-confirm {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
    height: 121px;
    margin-bottom: -4px;
    padding: $components-toastnotification-m-content-padding-top
    $components-toastnotification-m-content-padding-right
    $components-toastnotification-m-content-padding-bottom
    $components-toastnotification-m-content-padding-left;
    position: relative;

    .body {
      align-items: flex-start;
      align-self: stretch;
      display: flex;
      flex: 0 0 auto;
      gap: $components-toastnotification-m-text-spacing;
      position: relative;
      width: 100%;

      .text {
        align-items: flex-start;
        display: flex;
        flex: 1;
        flex-direction: column;
        flex-grow: 1;
        gap: $components-toastnotification-m-header-spacing;
        overflow: hidden;
        position: relative;

        .header {
          align-self: stretch;
          color: $components-toastnotification-primary-warning-header-color;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 20px;
          margin-top: -1px;
          position: relative;
        }

        .subtitle {
          color: $components-toastnotification-primary-warning-subtitle-color;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0;
          line-height: 20px;
          margin-right: -14px;
          position: relative;
          width: 299px;
        }
      }

      .close-button-instance {
        flex: 0 0 auto !important;
      }
    }

    .action-buttons {
      align-items: center;
      display: flex;
      gap: $components-toastnotification-m-actionbuttons-spacing;
      height: 32px;
      position: relative;
      width: 283px;

      .function-button {
        cursor: pointer;
        align-items: center;
        display: flex;
        gap: $components-functionbutton-s-spacing;
        justify-content: center;
        position: relative;
        width: 87px;

        .label-wrapper {
          align-items: flex-start;
          display: inline-flex;
          flex: 0 0 auto;
          padding: $components-functionbutton-s-label-padding-vertical 0px
          $components-functionbutton-s-label-padding-vertical 0px;
          position: relative;

          .label {
            color: $components-functionbutton-secondary-label-color-default;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 20px;
            margin-top: -1px;
            position: relative;
            white-space: nowrap;
            width: fit-content;
          }
        }
      }

      .div {
        cursor: pointer;
        align-items: center;
        display: flex;
        gap: $components-functionbutton-s-spacing;
        justify-content: center;
        position: relative;
        width: 54px;

        .label-wrapper {
          align-items: flex-start;
          display: inline-flex;
          flex: 0 0 auto;
          padding: $components-functionbutton-s-label-padding-vertical 0px
          $components-functionbutton-s-label-padding-vertical 0px;
          position: relative;

          .text-wrapper {
            color: $components-functionbutton-tertiary-label-color-hover;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 20px;
            margin-top: -1px;
            position: relative;
            white-space: nowrap;
            width: fit-content;
          }
        }

        .focus-layer-instance {
          border-radius: $theme-border-radius-m !important;
          height: 32px !important;
          left: -4px !important;
          position: absolute !important;
          top: -4px !important;
          width: 62px !important;
        }
      }
    }
  }
}
.focus-layer {
  border: 4px solid;
  border-color: $components-focus-soft-color;
  height: 56px;
  position: relative;
  width: 56px;
}
.close-button {
  align-items: center;
  background-color: $components-closebutton-primary-bg-color-default;
  border: 0px solid;
  border-color: $components-closebutton-primary-border-color-default;
  border-radius: $components-closebutton-s-border-radius;
  box-shadow: inset 0px 0px 0px transparent;
  display: inline-flex;
  justify-content: center;
  position: relative;

  .wrapper {
    cursor: pointer;
    align-items: center;
    display: flex;
    height: $components-closebutton-s-height;
    justify-content: center;
    position: relative;
    width: $components-closebutton-s-height;

    .element-navigation-close {
      height: 24px !important;
      position: relative !important;
      width: 24px !important;
    }
  }
}
