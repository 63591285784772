.modal {
  color: red;
  :global {
    .ant-message-custom-content {
      align-items: flex-start !important;
      .anticon {
        margin-top: 2px !important;
      }
    }
  }
  &__list {
    position: relative;
    max-width: 700px;
    :global {
      .ant-list-header {
        padding: 0 !important;
        border: none;
      }
      .ant-list-item {
        border-block-end: none !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
      .ant-list-item:last-of-type {
        padding-bottom: 0;
      }
    }
  }
  &__close {
    position: absolute;
    top: -15px;
    right: -30px;
    border: none !important;
  }
}