@import '../../assets/scss/variables';

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  min-height: 61px;
  padding: 5px 8px;
  border-bottom: 1px solid $color-border-header;
}
.notification {
  :global {
    .ant-avatar {
      background-color: transparent !important;
      cursor: pointer;
    }

    .ant-badge-count {
      min-width: 16px;
      height: 16px !important;
      padding: 0;
      font-size: 10px;
      line-height: 1.7 !important;
      background: $error-color;
      translate: -7px 8px;
    }

    .ant-scroll-number-only {
      height: 12px !important;
    }
  }

  &__list {
    padding: 10px;
    color: $color-text-default;
  }
  &__title {
    font-size: 20px;
    color: $color-text-default;
    margin: 0 !important;
  }
}