@import "../../../assets/scss/_variables";

.button {
  background-color: $button-color;
  color: $button-color--text;
  border-radius: $button-color--radius;
  cursor: pointer;
  border: 0;
  width: 90px;
  height: 32px;
  &.primary {
    &:hover:before {
      background-color: $button-color--hover;
      border: 0;
      border-radius: $button-color--radius;
    }

    &:hover {
      background-color: $button-color--hover;
      border: 0;
      border-radius: $button-color--radius;
      cursor: pointer;
    }
  }
  &.default {
    border-radius: $button-color--radius;
    border: 1px solid $button-color--default;
    width: 32px;
    color: $button-color--default;
    height: 32px;
    background-color: transparent;
    &:active{
      background-color: $button-color--hover;
      color: $button-color--text;
      border: 1px solid $button-color--hover;
      border-radius: $button-color--radius;
    }
    &:hover{
      color: $button-color--hover;
      border: 1px solid $button-color--hover;
      border-radius: $button-color--radius;
      cursor: pointer;
    }
  }
  &.text {
    width: 32px;
    color: $button-color--default;
    height: 32px;
    background-color: transparent;
    border: 1px solid transparent;
    display:inline-block;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    &:active{
      border: 1px solid transparent;
    }
    &:hover{
      cursor: pointer;
      border: 1px solid transparent;
    }
  }
  &.icon {
    background-color: $button-color--text;
    display: flex;
    width: 24px;
    height: 24px;
    padding: 3px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: $button-color--radius;
    border: 1px solid $button-color--border-icon;
    transition-property: none;
    &:hover{
      border-radius: $button-color--radius;
      border: 1px solid #109BFF;
      cursor: pointer;
    }
  }
}
.email-icon:hover {
  fill: #109BFF;
}
.telegram-icon:hover {
  fill: #20A0E2;
}
.watsapp-icon:hover{
  fill: #0DC043;
}

