@import '../../../../../assets/scss/variables';

.pdf{
    width: 595px !important;
    &__page {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 23px 23px 26px 24px;
    }
    &__header{
        // display: flex;
        // flex-direction: column;
        margin-bottom: 5px;
    }
    &__content {
        display: flex;
        flex-direction: column;
        gap: 7px;
        position: relative;
        &-text {
          margin: 0 !important;
        }
    }
    &__requestInfo{
        display: flex;
        align-items: flex-end;
    }
    &__line {
        min-width: 73px;
        font-size: 10px !important;
        font-weight: 500 !important;
        text-align: center !important;
        color: $black-color;
        border-bottom: 0.25px solid #969799;
    }
    .sign__post {
        position: relative; /* Добавьте это, если не было */
        &::after {
          position: absolute;
          left: 45%;
          font-size: 5px;
          font-weight: 400;
        }
      }
    .sign__line{
        position: relative;
        display: flex;
        justify-content: space-around;
    }
    &__text{
        font-size: 10px !important;
    }
    &__sign{
        display: flex;
        flex-direction: column;
        gap: 21px;
        position: relative;
        &__field{
            // margin-bottom: 20px;
            border-top: 1px solid #969799;
            font-size: 5px;
            display: flex;
            justify-content: space-evenly;
        }
    }
    &__print{
        padding: 10px 30px;
        background-color: #e64545;
        color: aqua;
    }
    :global(:where(.css-dev-only-do-not-override-abkopn).ant-modal .ant-modal-close){
        position: absolute;
        bottom: 10px !important;
    }
    &__container{
        margin-bottom: 30px;
    }
    &__table {
        margin-top: 3px;
        font-size: 9px;
        width: 100%;
        table-layout: fixed; 
        word-wrap: break-word; 
        border-collapse: collapse; 
        &__cel {
            border: 1px solid #969799;
            padding: 2px;
            padding-left: 15px;
            height: 22px; 
            max-height: none;
            overflow-wrap: break-word;
            word-break: break-word;
            text-align: left; 
            vertical-align: middle;
            line-height: 85%;
            position: relative;
            &__title{
                font-size: 10px;
                font-weight: 600;
                line-height: 100%;
            }
            &__text{
                font-size: 9px;
                line-height: 85%;
                font-weight: 500;
                &__note{
                    font-size: 8px;
                    font-weight: 300;
                    line-height: 105%;
                }
                :global(.ant-typography-edit){
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 5%;
                    width: 160px;
                    height: 20px;
                }
            }
            .editable-checked {
                :global(.ant-input-outlined:focus-within){
                    border: none;
                    box-shadow: none;
                    min-height: 20px;
                    max-height: 20px;
                }
            }
            :global(.ant-typography-edit-content){
                border: none;
                margin-top: -2px;
                :global(.ant-input-outlined:focus-within){
                    border: none;
                    box-shadow: none;
                    height: 20px !important;
                    font-size: 9px;
                    overflow-y: clip;
                }
                :global(.ant-typography-edit-content-confirm){
                    display: none;
                }
            }
            :global(.ant-input){
                min-height: 20px;
            }
        }
        &__cel:empty::after {
            content: "\00a0"; 
            display: block;
            height: 15px; 
        }
    }
    .signatures{
        position: absolute;
        bottom: -14%;
        left: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
            position: absolute;
            right: -32%;
        }
    }

}
.margin{
    margin-right: 15px;
}

@media print {
    .signatures{
        position: absolute;
        bottom: -14%;
        left: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
            position: absolute;
            right: -32%;
        }
    }
}